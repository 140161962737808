import React from "react";
import PropTypes from "prop-types";
import { VideoCard } from "@gonoodle/gn-universe-ui";
import { useRouter } from "next/router";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";

import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";
import {
  ROUTE_PREFIX,
  SPONSORED_PARTNER_TYPES,
  IMPRESSION_TYPES,
  AD_PRODUCTS_TYPES,
} from "../../constants";
import { WithContentLink } from "../../containers";
import { useImpression } from "../../hooks";
import FavoriteButton from "../FavoriteButton";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

const TitleWithLink = WithContentLink("span");

export default function VideoTile({
  id,
  slug,
  duration,
  sponsor,
  subtitle,
  title,
  description,
  images,
  onPress,
  linkParams,
  impressionsProps,
  testId,
}) {
  const router = useRouter();

  const inViewRef = useImpression({
    properties: {
      ...impressionsProps,
      impressionType: IMPRESSION_TYPES.VIDEO_TILE,
      adProductType: AD_PRODUCTS_TYPES.VIDEO_THUMBNAIL,
      title,
    },
    enabled: !!impressionsProps,
  });

  return (
    <VideoCard
      ref={inViewRef}
      duration={duration}
      sponsorExpDate={sponsor && sponsor.expiration}
      subtitle={subtitle}
      testId={testId}
      title={
        <TitleWithLink
          className="before:block before:absolute before:inset-0 before:cursor-pointer"
          id={id}
          slug={slug}
          contentLinkAsBase={`/${ROUTE_PREFIX.VIDEOS}`}
          contentLinkHrefBase={`/${ROUTE_PREFIX.VIDEOS}/[...identity]`}
          linkParams={linkParams}
          events={[
            {
              event: "Link Clicked",
              properties: {
                title,
                sourceElement: linkParams?.sourceElement,
                sourceName: linkParams?.sourceName,
                sourcePage: linkParams?.sourcePage,
                sourcePageType: linkParams?.sourcePageType,
                ...getUtmParamsFromQueryParams(router.query),
              },
            },
          ]}
        >
          {title}
        </TitleWithLink>
      }
      description={description}
      images={images}
      handleClick={() => {
        onPress();
      }}
      actions={
        <FavoriteButton
          type={CONTENT_TYPES.VIDEO}
          id={id}
          title={title}
          analyticsProps={{
            sourcePage: linkParams?.sourcePage,
            sourcePageType: linkParams?.sourcePageType,
          }}
        />
      }
    />
  );
}

VideoTile.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  sponsor: PropTypes.shape({
    expiration: PropTypes.string,
    type: PropTypes.oneOf([
      SPONSORED_PARTNER_TYPES.LONG_TERM_SPONSOR,
      SPONSORED_PARTNER_TYPES.PROMOTIONAL_ACTIVITY,
      "channel",
    ]).isRequired,
  }),
  subtitle: PropTypes.string,
  images: PropTypes.shape({ tile: ImageSourcesType }).isRequired,
  impressionsProps: PropTypes.shape({}),
  linkParams: PropTypes.shape({}),
  onPress: PropTypes.func,
  testId: PropTypes.string,
};

VideoTile.defaultProps = {
  subtitle: undefined,
  sponsor: undefined,
  impressionsProps: undefined,
  linkParams: undefined,
  onPress: () => {},
  testId: undefined,
};
