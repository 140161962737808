import React from "react";
import PropTypes from "prop-types";
import { PrintableCard } from "@gonoodle/gn-universe-ui";
import { ImageSourcesType } from "@gonoodle/gn-universe-ui/types";
import { useRouter } from "next/router";
import { CONTENT_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import FavoriteButton from "../FavoriteButton";
import { WithContentLink } from "../../containers";
import { ROUTE_PREFIX } from "../../constants";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

const PrintableCardInfoWithLink = WithContentLink(PrintableCard.Info);

export default function PrintableTile({
  id,
  slug,
  mode,
  sources,
  title,
  description,
  linkParams,
  format,
}) {
  const router = useRouter();

  return (
    <>
      <PrintableCard.Cover mode={mode} format={format}>
        <PrintableCard.Content sources={sources} />
      </PrintableCard.Cover>
      <div className="flex flex-row mt-xs space-x-sm justify-between items-start">
        <PrintableCardInfoWithLink
          className="before:block before:absolute before:inset-0 before:cursor-pointer"
          id={id}
          slug={slug}
          contentLinkAsBase={`/${ROUTE_PREFIX.ACTIVITIES}`}
          contentLinkHrefBase={`/${ROUTE_PREFIX.ACTIVITIES}/[...identity]`}
          linkParams={linkParams}
          mode={mode}
          title={title}
          description={format === "landscape" ? description : undefined}
          events={[
            {
              event: "Link Clicked",
              properties: {
                title,
                sourceElement: linkParams?.sourceElement,
                sourceName: linkParams?.sourceName,
                sourcePage: linkParams?.sourcePage,
                sourcePageType: linkParams?.sourcePageType,
                ...getUtmParamsFromQueryParams(router.query),
              },
            },
          ]}
        />
        <FavoriteButton
          type={CONTENT_TYPES.ACTIVITY}
          title={title}
          id={id}
          analyticsProps={{
            sourcePage: linkParams?.sourcePage,
            sourcePageType: linkParams?.sourcePageType,
          }}
        />
      </div>
    </>
  );
}

PrintableCard.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["dark", "light"]),
  sources: ImageSourcesType.isRequired,
  title: PropTypes.string.isRequired,
  linkParams: PropTypes.shape({}),
  format: PropTypes.oneOf(["landscape", "square"]),
};
