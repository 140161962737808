import React from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";
import {
  concatenateQueryParams,
  isLocalURL,
  isSubdomain,
} from "@gonoodle/gn-universe-utils";

import api from "../api";

const client = api();

const Link = React.forwardRef(function Link(
  {
    children,
    events,
    className,
    href,
    as,
    passHref,
    shallow,
    replace,
    isOpen,
    ...rest
  },
  forwardedRef,
) {
  const parsedHref = React.useMemo(() => {
    if (isLocalURL(href)) {
      return href;
    }

    if (isSubdomain(process.env.NEXT_PUBLIC_WEB_URL, href)) {
      return href;
    }

    return concatenateQueryParams("/external-link-disclaimer", {
      href,
    });
  }, [href]);

  const onRouteChange = () => {
    events.forEach((event) => {
      client.trackAnalyticsEvents(event).catch(() => {});
    });
  };

  return (
    <NextLink
      href={parsedHref}
      as={as}
      passHref={passHref}
      shallow={shallow}
      replace={replace}
      {...rest}
      className={className}
      onClick={(event) => {
        onRouteChange();
        rest?.onClick?.(event);
      }}
      ref={forwardedRef}
      draggable={false}
    >
      {children}
    </NextLink>
  );
});

export default Link;

Link.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  passHref: PropTypes.bool,
  replace: PropTypes.bool,
  shallow: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired,
      properties: PropTypes.shape({}).isRequired,
    }),
  ),
};

Link.defaultProps = {
  as: undefined,
  passHref: false,
  replace: false,
  shallow: false,
  events: [],
  className: undefined,
};
